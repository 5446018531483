/* Footer */
#footer {
  background: #010351;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-top {
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 15px;
  background: #010246;
  color: #fff;
  border-top: 4px solid #1acc8d;
  text-align: center;
  padding: 30px 20px;
}
#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #1acc8d;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #61ebba;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  color: #4be8b0;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 10px;
  background: #fff;
  padding: 6px 10px 6px 15px;
  position: relative;
  border-radius: 50px;
}

#footer .footer-newsletter input {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-newsletter input:focus {
  outline: none;
}

#footer .footer-newsletter button {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  background: #1acc8d;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

#footer .footer-newsletter button:hover {
  background: #149f6e;
}

#footer .footer-newsletter .NewsletterAlert {
  margin: 10px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
}

#footer .copyright {
  border-top: 1px solid #010479;
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 2px;
  text-align: center;
  font-size: 10px;
  color: #fff;
}
@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}
