/* Timeline */
.timeline-wrapper {
  font-family: "Montserrat", sans-serif;
  position: relative;
  padding: 10px 0;
}

.timeline-wrapper:last-child:before {
  height: 0;
}

.timeline-wrapper:before {
  content: "";
  background: #191ca9;
  width: 3px;
  height: 100%;
  position: absolute;
  left: 38px;
}

.timeline-yr {
  background: #191ca9;
  border-radius: 100%;
  position: absolute;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
}

.timeline-yr span {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  display: block;
  line-height: 75px;
}

.timeline-info {
  display: inline-block;
  vertical-align: top;
  max-width: 432px;
  margin-left: 6em;
}

.timeline-info small {
  color: #474559;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  padding: 0px;
  margin-bottom: 0px;
}
