/* Skills */
.skills .part h3 {
  font-weight: 600;
  font-size: 15px;
  color: #010483;
  text-align: center !important;
  justify-content: center !important;
  margin-bottom: 20px;
}

.skills .row>*{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.skills .row .section-title h2 {
  margin: 20px 0 0 0;
}

@media only screen and (max-width: 1000px) {
  .skills .row .section-title h2 {
    font-size: 14px;
    font-weight: 500;
  }
}

.skills .icon-box {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 60px;
  margin: 2%;
  background: #f5f5ff;
  transition: ease-in-out 0.3s;
}

.skills .icon-box img {
  height: 50px;
  padding: 2%;
  margin-right: 10px;
}

.skills .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.skills .icon-box h3 a {
  color: #010483;
  transition: ease-in-out 0.3s;
}

.skills .icon-box h3 a:hover {
  color: #01036f;
}

.skills .icon-box:hover {
  background: #ebebff;
}

@media only screen and (max-width: 600px) {
  .skills .icon-box {
    padding: 5px;
    margin: 1% important;
  }
  .skills .icon-box img {
    height: 35px;
    padding: 1%;
  }
  .skills .icon-box h3 {
    font-size: 14px;
  }
}
