/* Contact */
.contact .info {
  width: 100%;
  background: #fff;
}
.contact .info i {
  font-size: 20px;
  color: #3f43fd;
  float: left;
  width: 40px;
  height: 40px;
  background: #f0f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  color: #010483;
}
.contact .info p {
  padding: 0 0 0 60px;
  font-size: 14px;
  color: #0205a1;
}
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}
.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #1acc8d;
  color: #fff;
}
.contact .contact-form {
  width: 100%;
  background: #fff;
}
.contact .contact-form .form-group {
  padding-bottom: 8px;
}
.contact .contact-form input,
.contact .contact-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
.contact .contact-form input {
  height: 44px;
}
.contact .contact-form textarea {
  padding: 10px 12px;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
