/* Hero section */

#hero {
  width: 100%;
  background: url("../../img/hero-bg.jpg");
  background: rgba(2, 5, 161, 0.91);
  position: relative;
  padding: 60px 0 0 0;
}
#hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero h1 {
  margin: 0 0 20px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: rgba(255, 255, 255, 0.8);
}
#hero h1 span {
  color: #fff;
  border-bottom: 4px solid #1acc8d;
}
#hero h2 {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 40px;
  font-size: 24px;
}
#hero .main-btn-hero {
  background: rgba(2, 5, 161, 0.91);
  border: 2px solid #ffffff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 30px;
  border-radius: 50px;
}
#hero .main-btn-hero:hover {
  color: rgba(2, 5, 161, 0.91);
  background: white;
}
#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 991px) {
  #hero {
    padding-top: 60px;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    max-width: 100%;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  #hero .destext {
    text-align: center;
    justify-content: center !important;
  }
  #hero .hero-img img {
    width: 100%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.hero-waves {
  display: block;
  margin-top: 60px;
  width: 100%;
  height: 60px;
  z-index: 5;
  position: relative;
}

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
