/* Header */
#header {
  height: 80px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(1, 4, 136, 0.9);
}
#header.header-transparent {
  background: transparent;
}
#header.header-scrolled {
  background: #191ca9;
  height: 60px;
}
#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
}
#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}
#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 50px;
}

/* Navigation Menu */

/* Desktop Navigation */
.navbar {
  padding: 0;
}
.navbar button {
  background: #fafaff00;
  border: none;
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar button,
.navbar button:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: 0.3s;
}
.navbar button i,
.navbar button:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar > ul > li > button:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 3px;
  left: 30px;
  background-color: #1acc8d;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}
.navbar button:hover:before,
.navbar li:hover > button:before,
.navbar .active:before {
  visibility: visible;
  width: 25px;
}
.navbar button:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > button {
  color: #fff;
}

/* Mobile Navigation  */
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile button {
  background: #fafaff00;
  border: none;
  padding: 0;
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 3, 91, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile ul li {
  text-align: center !important;
  align-items: center;
}
.navbar-mobile > ul > li > button:before {
  text-align: center !important;
}
.navbar-mobile button,
.navbar-mobile button:focus {
  text-align: center!important;
  padding: 5px 20px;
  font-size: 20px;
  font-weight: bolder;
  color: #0205a1;
}
.navbar-mobile button:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > button {
  color: #3f43fd;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.nav-link{
  text-align: center !important;
}