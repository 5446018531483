/* pcertificatess */
.pcertificates {
  font-family: "Poppins", sans-serif;
}

.certificate-card {
  margin: 5px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.certificate-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
}
.certificate-card h3 {
  margin: 10px 0px 0px 0px;
  font-weight: 600;
  font-size: 20px;
  color: #010483;
}

.certificate-card .badge {
  background: #010483;
  color: #fff;
  padding: 5px 10px;
  margin: 1px;
}
.certificate-card .main-btn {
  padding: inherit 5px;
  margin: 2px 2px;
  border: 2px solid #010483;
}
