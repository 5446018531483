.breadcrumbs {
  padding: 20px 0;
  background-color: #fafaff;
  min-height: 40px;
  margin-top: 60px;
}
.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.breadcrumbs button{
  background: #fafaff00;
  border: none;
  padding: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #000000;
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 64px;
  }
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}
