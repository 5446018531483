/* Details */
.project-card {
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  position: relative;
}
.project-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
}

.project-card .badge {
  background: #010483;
  color: #fff;
  padding: 5px 10px;
  margin: 1px;
}
.project-card button {
  background: #fafaff00;
  border: none;
}
.pprojects h3 {
  margin: 20px 0px;
  font-weight: 600;
  font-size: 26px;
  color: #010483;
}
.pprojects img {
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

.project-card {
  margin-bottom: 40px;
}

.pprojects .main-btn {
  padding: inherit 10px;
  margin: 10px 2px;
  border: 2px dashed #010483;
}

/* Small Screen */
@media screen and (max-width: 768px) {
  .pprojects .content h3 {
    margin: 10px 0px;
    font-size: 20px;
  }
}
